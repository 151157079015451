import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { FiLock,FiCheck } from 'react-icons/fi';

const countryCodes = {
  Afghanistan: "AF",
  Albania: "AL",
  Algeria: "DZ",
  Andorra: "AD",
  Angola: "AO",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  Brazil: "BR",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  Colombia: "CO",
  Comoros: "KM",
  "Congo (Brazzaville)": "CG",
  "Congo (Kinshasa)": "CD",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  "Eswatini (Swaziland)": "SZ",
  Ethiopia: "ET",
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Greece: "GR",
  Grenada: "GD",
  Guatemala: "GT",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  Honduras: "HN",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  "Korea, North": "KP",
  "Korea, South": "KR",
  Kosovo: "XK",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Mauritania: "MR",
  Mauritius: "MU",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Morocco: "MA",
  Mozambique: "MZ",
  "Myanmar (Burma)": "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  "North Macedonia (formerly Macedonia)": "MK",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  Palestine: "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Poland: "PL",
  Portugal: "PT",
  Qatar: "QA",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Vatican City": "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};

const PaymentForm = ({ closeModal, price, submitlisting }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardComponent, setCardComponent] = useState(null);
  const [jsPaymentClient, setJsPaymentClient] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    address: "",
    email: "",
    country: "",
    city: "",
    zip: "",
    paymentMethod: "BankCard",
  });

  const [isBank, setIsBank] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Filter countries based on the search term
  const filteredCountries = Object.keys(countryCodes).filter((country) =>
    country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    initializePaymentClient();
  }, []);

  const initializePaymentClient = () => {
    if (!window.TwoPayClient) return;

    const client = new window.TwoPayClient("255164238808");
    setJsPaymentClient(client);

    const component = client.components.create("card");
    component.mount("#card-element");
    setCardComponent(component);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({ ...prevDetails, [name]: value }));

    if (value === "BankCard") {
      setIsBank(true);
    } else if (value === "Paypal") {
      setIsBank(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!jsPaymentClient || !cardComponent) {
      console.error("Payment client or card component is not initialized.");
      return;
    }

    jsPaymentClient.tokens
      .generate(cardComponent, { name: billingDetails.name })
      .then((response) => {
        console.log("Generated token:", response.token);
        handlePayment(response.token);
      })
      .catch((error) => {
        console.error("Error generating token:", error);
        setError("Token generation error: " + error.message);
        setLoading(false);
      });
  };

  const handlePayment = async (eesToken) => {
    try {
      setError(null);

      const response = await axios.post(
        "https://www.api.affiliatedrefer.com/paymentgateway/api/payment",
        {
          eesToken,
          amount: price,
          billingDetails,
        }
      );

      console.log("Payment successful", response.data);
      setLoading(false);
      notification.success({
        message: "Payment Successful",
        duration: 3,
      });
      closeModal();
      submitlisting();
    } catch (error) {
      console.error("Payment error:", error.response?.data || error.message);
      notification.error({
        message: "Processing Payment Failed",
        duration: 3,
      });
      setLoading(false);
    }
  };

  return (
    <div className="p-4" style={{ maxHeight: "80vh", overflowY: "auto" }}>
      <div className="max-w-md mx-auto p-6 bg-white rounded shadow relative">
        <h2 className="text-2xl mb-4">Payment Information</h2>
        <button
          onClick={closeModal}
          className="absolute top-5 right-5 text-gray-600 hover:text-gray-800"
          aria-label="Close"
        >
          &times;
        </button>
        {error && (
          <div className="bg-red-100 text-red-700 p-3 mb-4 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} id="payment-form">
          <div
            className="space-y-1 pr-4"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            <div className="form-group">
              <label className="block mb-1" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full border rounded p-2"
                name="name"
                value={billingDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="block mb-1" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full border rounded p-2"
                name="email"
                value={billingDetails.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="block mb-1" htmlFor="country_code">
                Country 
              </label>
              <input
                type="text"
                className="w-full border rounded p-2 mb-1"
                placeholder="Search Country"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsDropdownOpen(true)}
              />
              {isDropdownOpen && (
                <div
                  className="max-w-md mx-auto  border rounded p-2 overflow-y-auto max-h-40"
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    zIndex: 10,
                  }}
                >
                  {filteredCountries.length > 0 ? (
                    filteredCountries.map((country) => (
                      <div
                        key={countryCodes[country]}
                        className="cursor-pointer p-2 hover:bg-gray-200"
                        onClick={() => {
                          handleChange({
                            target: {
                              name: "country",
                              value: countryCodes[country],
                            },
                          });

                          setSearchTerm(country);
                          setIsDropdownOpen(false);
                        }}
                      >
                        {country} ({countryCodes[country]})
                      </div>
                    ))
                  ) : (
                    <div className="p-2">No results found</div>
                  )}
                </div>
              )}
            </div>
            
            <div className="form-group">
              <label className="block mb-1" htmlFor="city">
                City
              </label>
              <input
                type="text"
                id="city"
                className="w-full border rounded p-2"
                name="city"
                value={billingDetails.city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="block mb-1" htmlFor="address">
                Address
              </label>
              <input
                type="text"
                id="address"
                className="w-full border rounded p-2"
                name="address"
                value={billingDetails.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="block mb-1" htmlFor="zip">
                Zip Code
              </label>
              <input
                type="text"
                id="zip"
                className="w-full border rounded p-2"
                name="zip"
                value={billingDetails.zip}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="block mb-1" htmlFor="payment_method">
                Payment Method
              </label>
              <select
                className="w-full border rounded p-2"
                name="paymentMethod"
                id="payment_method"
                required
                onChange={handleChange}
                value={billingDetails.paymentMethod}
              >
                <option value="BankCard">Bank</option>
                <option value="Paypal">Paypal</option>
              </select>
            </div>

            {isBank ? (
              
              <>
              
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                 
                   <p className="my-3 p-0" style={{ fontSize: '12px', color: 'gray', marginLeft: '8px' }}>
                     For Visa & MasterCard payments please select the PayPal option 
                    </p>
                  
               </div>
               </>
              
            ) : (
              <>
                 
              </>
            )}
            
            <div id="card-element"></div>

            {isBank ? (
              
              <>
              
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                   <FiLock style={{ color: 'gray', fontSize: '15px' }} />
                   <p className="my-3 p-0" style={{ fontSize: '12px', color: 'gray', marginLeft: '8px' }}>
                     All transactions are secured by SSL certificate
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                   <FiCheck style={{ color: 'gray', fontSize: '15px', marginTop: '-10px' }} />
                    <p className="my-3 p-0" style={{ fontSize: '12px', color: 'gray', marginLeft: '8px', marginTop: '-2px' }}>
                     The payment processing is securely handled by 2Checkout
                     </p>
                  </div>
               </div>
               </>
              
            ) : (
              <>
                 <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                   <FiLock style={{ color: 'gray', fontSize: '15px' }} />
                   <p className="my-3 p-0" style={{ fontSize: '12px', color: 'gray', marginLeft: '8px' }}>
                     All transactions are secured by SSL certificate
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                   <FiCheck style={{ color: 'gray', fontSize: '15px', marginTop: '-10px' }} />
                    <p className="my-3 p-0" style={{ fontSize: '12px', color: 'gray', marginLeft: '8px', marginTop: '-2px' }}>
                     The payment processing is securely handled by 2Checkout
                     </p>
                  </div>
               </div>
              </>
            )}

            

            <button
              className="btn btn-primary p-2 rounded text-white"
              style={{ backgroundColor: "#1678ff" }}
              type="submit"
              disabled={loading}
            >
              {loading ? "Processing..." : `Pay ${price} and Enlist`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
